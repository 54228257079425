import React from 'react';
import { Link } from "gatsby";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; Look At Me - Daycare 2019. All Rights Reserved.</p>
        <ul className="list-inline">
          
          <li className="list-inline-item">
            <Link to="/privacy/">Privacy</Link>
          </li>

          <li className="list-inline-item">|</li>

          <li className="list-inline-item">
            <Link to="/terms/">Terms</Link>
          </li>

          <li className="list-inline-item">|</li>

          <li className="list-inline-item">
            <Link to="/about/">About</Link>
          </li>

        </ul>
      </div>
    </footer>
  );
}
